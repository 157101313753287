export default {
    tableFields:   [
        {
            name:      '__component:badge-column',
            title:     '',
            dataClass: 'text-center',
            width:     '4%'
        },
        {
            name:      'device.name',
            title:     'Código Dispositivo',
            sortField: 'device.name',
            width:     '20%'
        },
        {
            name:      'device.mac',
            title:     'Mac',
            sortField: 'device.mac',
            width:     '20%'
        },
       /* {
            name:      'device.serial',
            title:     'Serial',
            sortField: 'device.serial',
            width:     '24%'
        },*/
        {
            name:      'device.model',
            title:     'Modelo',
            sortField: 'device.model',
            width:     '10%'
        },
        {
            name:      'device.softwareversion',
            title:     'Soft. Ver.',
            sortField: 'device.softwareversion',
            width:     '10%'
        },
        {
          name:      'status.battery',
          title:     'Bateria',
          sortField: 'status.battery',
          width:     '10%'
      },
      {
        name:      'last_conexion',
        title:     'Última Conexión',
        sortField: 'last_conexion',
        width:     '10%'
    },
        {
            name:       '__slot:action',
            title:      'Detalles',
            titleClass: 'center aligned',
            dataClass:  'center aligned',
            width:      '25%',
        },
        {
            name:       '__slot:active',
            title:      'Estatus',
            titleClass: 'center aligned',
            dataClass:  'center aligned',
            width:      '25%',
        },
    ],
    sortFunctions: {
        'device.name': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
        'device.mac': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
    }
}
